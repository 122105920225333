<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" scrollable>
      <v-card :disabled="saveLoading" :loading="saveLoading">
        <v-card-title>
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-8">

          <div class="text-center">
            <v-avatar class="bg-red-subtle" size="90">
              <v-icon color="red" size="50">
                mdi-delete-empty-outline
              </v-icon>
            </v-avatar>

            <h2 class="my-5">Êtes-vous sûr?</h2>

            <p>
              Êtes-vous sûr de vouloir supprimer ce point de vente <br> De la liste liée à
              <span class="font-weight-medium">GIFTY</span> ?
            </p>
          </div>

          <div v-if="showLoading">
            <v-skeleton-loader type="article,article"/>
          </div>

          <div v-else>

            <v-simple-table v-if="salepoint && salepoint.salepoint"
                            class="rounded-lg table-border my-3">
              <template v-slot:default>
                <tbody>
                <tr>
                  <td class="font-weight-medium text-no-wrap">
                    <v-icon dense left>mdi-code-array</v-icon>
                    Code
                  </td>
                  <td class="primary--text font-weight-medium">{{ salepoint.salepoint.code }}</td>
                </tr>
                <tr class="grey lighten-4">
                  <td class="font-weight-medium">
                    <v-icon dense left>mdi-account</v-icon>
                    Nom complet
                  </td>
                  <td>{{ salepoint.salepoint.name }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">
                    <v-icon dense left>mdi-store</v-icon>
                    Magasin
                  </td>
                  <td>{{ salepoint.salepoint.store_name }}</td>
                </tr>
                <tr class="grey lighten-4">
                  <td class="font-weight-medium text-no-wrap">
                    <v-icon dense left>mdi-phone</v-icon>
                    N° de téléphone
                  </td>
                  <td>{{ salepoint.salepoint.phone }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">
                    <v-icon dense left>mdi-map-marker</v-icon>
                    Adresse
                  </td>
                  <td>{{ salepoint.salepoint.address }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-alert v-if="!Object.keys(giftyAccount).length && !showLoading"
                     border="bottom"
                     dense
                     class="fs-14"
                     prominent
                     text
                     type="warning">
              Cet utilisateur n'a pas été trouvé dans l'application GIFTY
            </v-alert>

            <v-simple-table v-if="Object.keys(giftyAccount).length && !showLoading"
                            class="rounded-lg table-border my-3">
              <template v-slot:default>
                <tbody>
                <tr>
                  <td class="font-weight-medium text-no-wrap py-4" colspan="2">
                    <v-avatar>
                      <GiftyIcon/>
                    </v-avatar>
                    Information du compte GIFTY
                  </td>
                </tr>
                <tr class="grey lighten-4">
                  <td class="font-weight-medium">
                    <v-icon dense left>mdi-account</v-icon>
                    Nom complet
                  </td>
                  <td>{{ giftyAccount.name }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium text-no-wrap">
                    <v-icon dense left>mdi-phone</v-icon>
                    N° de téléphone
                  </td>
                  <td>{{ giftyAccount.phone }}</td>
                </tr>
                <tr class="grey lighten-4">
                  <td class="font-weight-medium text-no-wrap">
                    <v-icon dense left>mdi-calendar-heart</v-icon>
                    Date de naissance
                  </td>
                  <td>{{ giftyAccount.dob }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium text-no-wrap">
                    <v-icon dense left>mdi-gender-male-female</v-icon>
                    Sexe
                  </td>
                  <td>{{ giftyAccount.gender === 'male' ? 'Homme' : 'Femme' }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-textarea v-model.trim="remark"
                        :error-messages="errors.remark"
                        clearable
                        dense
                        label="Remarque(optionnel)"
                        outlined
                        placeholder="Entrez ici une remarque expliquant pourquoi vous souhaitez supprimer.."
                        rows="3">

            </v-textarea>
          </div>

        </v-card-text>

        <v-card-actions v-if="!showLoading" class="grey lighten-3">
          <v-spacer/>
          <v-btn :loading="saveLoading"
                 class="my-2"
                 color="red"
                 dark
                 depressed
                 @click="handleDelete">
            Supprimer
          </v-btn>
          <v-spacer/>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import GiftyIcon from "@/components/svg-icons/GiftyIcon.vue";
import {HTTP} from "@/http-common";

export default {
    components: {GiftyIcon},
    data() {
        return {
            showLoading: false,
            saveLoading: false,
            dialog: false,
            salepoint: {},
            index: null,
            remark: null,
            errors: {},
            giftyAccount: {},
        }
    },
    methods: {
        open(salepoint, index) {
            this.giftyAccount = {}
            this.index = index
            this.salepoint = salepoint
            this.dialog = true
            this.getGiftyAccount(salepoint.gifty_id)
        },
        handleDelete() {
            this.errors = {}
            this.saveLoading = true
            HTTP.post('/gifty-agency/delete-liaison', {
                salepoint_gifty_id: this.salepoint.id,
                remark: this.remark,
            }).then(() => {
                this.dialog = false
                this.$successMessage = 'Cet élément a été supprimé avec succès.'
                this.$emit('remove-column', this.index)
                this.saveLoading = false
            }).catch(err => {
                console.log(err)
                this.errors = err.response.data.errors
                this.saveLoading = false
            })
        },
        getGiftyAccount(giftyId) {
            this.showLoading = true
            axios.get(process.env.VUE_APP_GIFTY_URL + '/api/account/' + giftyId).then((res) => {
                this.giftyAccount = res.data.user
                this.showLoading = false
            }).catch(err => {
                if (err.response.status === 404) {
                    this.giftyAccount = {}
                }
                console.log(err)
                this.showLoading = false
            })
        }
    }
}
</script>

<style scoped>

</style>